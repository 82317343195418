@import '../base/colors'

$modal-z: 40 !default

$modal-background-background-color: rgba($black, 0.86) !default

$modal-content-width: 640px !default
$modal-content-margin-mobile: 20px !default
$modal-content-spacing-mobile: 160px !default
$modal-content-spacing-tablet: 40px !default

$modal-close-dimensions: 40px !default
$modal-close-right: 20px !default
$modal-close-top: 20px !default

$modal-card-spacing: 40px !default

$modal-card-head-background-color: $background !default
$modal-card-head-border-bottom: 1px solid $border !default
$modal-card-head-padding: 20px !default
$modal-card-head-radius: $radius-large !default

$modal-card-title-color: $text-strong !default
$modal-card-title-line-height: 1 !default
$modal-card-title-size: $size-4 !default

$modal-card-foot-radius: $radius-large !default
$modal-card-foot-border-top: 1px solid $border !default

$modal-card-body-background-color: $white !default
$modal-card-body-padding: 20px !default

.modal
  @extend %overlay
  align-items: center
  display: none
  flex-direction: column
  justify-content: center
  overflow: hidden
  position: fixed
  z-index: $modal-z
  &.is-active
    display: flex

.modal-background
  @extend %overlay
  background-color: $modal-background-background-color

.modal-content,
.modal-card,
.share-modal-card
  margin: 0 $modal-content-margin-mobile
  max-height: calc(100vh - #{$modal-content-spacing-mobile})
  overflow: auto
  position: relative
  width: 100%
  +tablet
    margin: 0 auto
    max-height: calc(100vh - #{$modal-content-spacing-tablet})
    width: $modal-content-width

.modal-close
  @extend %delete
  background: none
  height: $modal-close-dimensions
  position: fixed
  right: $modal-close-right
  top: $modal-close-top
  width: $modal-close-dimensions

.modal-card
  display: flex
  flex-direction: column
  max-height: calc(100vh - #{$modal-card-spacing})
  overflow: hidden
  -ms-overflow-y: visible

// Remove the trailing comma here
.modal-card-head,
.modal-card-foot
  align-items: center
  background-color: $modal-card-head-background-color
  padding: $modal-card-head-padding
  display: flex
  flex-shrink: 0
  position: relative

.modal-card-head-share-modal
  justify-content: flex-start
  border-top-left-radius: $modal-card-head-radius
  border-top-right-radius: $modal-card-head-radius
  .image
    padding: 0
    width: 100%
    height: 200px
    background-size: cover
    background-position: center
    background-repeat: no-repeat

.modal-card-head-update-modal-button
  background-color: $purple
  border-color: $grey-lighter
  border-width: $control-border-width
  color: $white
  cursor: pointer
  width: 25%
  height: 32px
  @if $button-family
    font-family: $button-family
  text-align: center
  border-radius: 4px

.project-share-modal
  width: 200px

.modal-card-head
  border-bottom: $modal-card-head-border-bottom
  border-top-left-radius: $modal-card-head-radius
  border-top-right-radius: $modal-card-head-radius

.modal-card-title
  color: $modal-card-title-color
  flex-grow: 1
  flex-shrink: 0
  font-size: $modal-card-title-size
  line-height: $modal-card-title-line-height

.modal-card-foot,
.share-modal-card-foot
  border-bottom-left-radius: $modal-card-foot-radius
  border-bottom-right-radius: $modal-card-foot-radius
  border-top: $modal-card-foot-border-top
  .button
    &:not(:last-child)
      margin-right: 0.5em

.modal-card-body
  +overflow-touch
  background-color: $modal-card-body-background-color
  flex-grow: 1
  flex-shrink: 1
  overflow: auto
  padding: $modal-card-body-padding

.share-modal-card-body
  +overflow-touch
  background-color: $modal-card-body-background-color
  flex-grow: 1
  flex-shrink: 1
  overflow: auto
  &-content-wrapper
    display: flex
    justify-content: center
    margin-top: 20px

.share-modal-card-input-area
  display: flex
  width: 100%

.modal-card-head-cancel-button
