.avatar
  overflow: hidden

  > img
    height: 100%
    width: 100%

.image.avatar
  
  &.default-media-avatar
    background-color: $grey
    background-repeat: no-repeat
    background-size: 42px 42px