html,
body
  width: 100%
  min-height: 100%
  background-color: $grey

main
  > .container
    margin-top: 54px

.is
  &-bordered-bottom
    border-bottom: 1px solid $grey-light
    margin-bottom: 10px

  &-bordered-top
    border-top: 1px solid $grey-light
