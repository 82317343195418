.icon
  background-position: center
  background-repeat: no-repeat
  vertical-align: middle

  &-check-circle
    background-image: url('../../assets/images/project/check-circle.svg')

  &-plus-circle
    background-image: url('../../assets/images/project/plus-circle.svg')

  &-like
    background-image: url('../../assets/images/project/icon-like.png')

  &-comment
    background-image: url('../../assets/images/project/icon-comment.png')

  &--is-inline-flex
    align-items: center
    margin-left: .5rem
