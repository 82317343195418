$header-padding: 1.5rem

.header-padding
  padding: $header-padding

.page-user-profile

  &-card

    &-header

      &-upper
        overflow: hidden

      &-backdrop
        max-height: 120px

      &-lower
        border-bottom: 1px solid $grey-light

        &-level
          margin-top: -61px

          figure
            &.default-media-avatar
              border: 3px solid white

          &-img,
          &-upload
            border: 4px solid #fff

          &-upload
            position: absolute
            top: 0
            width: 100%
            height: 96px
            border-radius: 50%

            &-content
              position: absolute
              top: 50%
              left: 50%
              transform: translate(-50%, -50%)

  &-post-update

    &-card

      &-content

        textarea
          border-radius: .5rem .5rem 0 0

      &-footer
        border-radius: 0 0 .5rem .5rem

        &-button

          &-send
            height: 32px
            width: 48px

        &-select

          select,
          &:not(.is-multiple)
            height: auto

  &-next-card
    h1
      margin-bottom: .5rem

    img
      width: 100%
      object-fit: cover

    &-content
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 15%), url('../../assets/svg/start-collaborating.svg')
      height: calc(100vh - 330px)
      background-repeat: no-repeat
      background-size: cover

      &-gradient
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.84) 47.92%, rgba(255, 255, 255, 0) 100%)
        height: 4.5rem

  &-sidebar

    &-addproject
      background: url('../../assets/svg/sidebar-addproject-bg.svg')

      &-header
        padding: .5rem

    &-skills

      &-footer

        &-icon
          margin-left: .5rem

  &-modal

    &-card
      overflow: overlay
      max-width: 500px

      &-body
        overflow: visible

        &-textarea
          height: 100px

      &-avatar
        display: flex
        justify-content: center

      &-avatar

        &-edit
          transition: .5s ease
          opacity: 0
          background: rgba(0,0,0, .5)
          width: 96px
          height: 48px
          bottom: 0
          border-bottom-left-radius: 96px
          border-bottom-right-radius: 96px

          &-button
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            width: auto

      &-footer
        position: sticky
        bottom: 0
        justify-content: flex-end
