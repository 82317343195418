// Spacing utility classes - This will generate margin and padding classes
// Syntax example:
//    .m-t-0 = margin-top: 0rem
//    .p-b-2 = padding-bottom: 0rem

$spacings: (0, 1, 2, 3, 4)
$sides: (top, right, bottom, left)

@each $space in $spacings
  @each $side in $sides
    .m-#{str-slice($side, 0, 1)}-#{$space}
      margin-#{$side}: #{$space}rem !important

    .p-#{str-slice($side, 0, 1)}-#{$space}
      padding-#{$side}: #{$space}rem !important
