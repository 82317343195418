@import "../base/colors"

.comments,
.comment-form
  &-media
    margin-bottom: 1rem

    &-content
      padding: .5em
      border-radius: .25em

      &-timestamp
        position: absolute
        top: .5em
        right: .5em

      &-control
        display: flex
        justify-content: flex-end

    figure
      margin-left: .5em
      margin-right: .5em

  &-toggle-text
    padding-left: .5em
    padding-top: 1rem

.comment-form
  &-control
    &-button
      border-radius: 2px
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      width: 50px

      // Fixed the disabled button styling
      &[disabled]
        background-color: $primary

    &-input
      width: calc(100% - 50px)
      height: 48px
      padding: 0
      border-radius: .25em 0 0 .25em
      border: 1px solid $grey-lighter
      background-color: $grey
      margin-bottom: 1em
      display: flex
      align-items: center

.like-button
  border: none
  display: flex
  align-items: center
  border-radius: 5px
  background-color: whitesmoke
  cursor: pointer

  &:hover
    background-color: #dbdada

.typeahead-popover
  background: #fff
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3)
  border-radius: 8px
  margin-top: 25px

  ul
    padding: 0
    list-style: none
    margin: 0
    border-radius: 8px
    max-height: 200px
    overflow-y: scroll

    li
      margin: 0
      min-width: 180px
      font-size: 14px
      outline: none
      cursor: pointer
      border-radius: 8px

      .selected
        background: #eee

  li
    margin: 0 8px
    padding: 8px
    color: #050505
    cursor: pointer
    line-height: 16px
    font-size: 15px
    display: flex
    align-content: center
    flex-direction: row
    flex-shrink: 0
    background-color: #fff
    border-radius: 8px
    border: 0

    figure
      display: flex
      width: 20px
      height: 20px
      user-select: none
      margin-right: 8px
      line-height: 16px
      background-size: contain
      background-repeat: no-repeat
      background-position: center

    .text
      display: flex
      line-height: 20px
      flex-grow: 1
      min-width: 150px

.mentions-menu
  width: 250px

.emoji-menu
  width: 200px