$percentages: 25

@each $percentage in $percentages
  .w-pc-#{$percentage}
    width: $percentage * 1% !important

$heights: 205

@each $height in $heights
  .h-px-#{$height}
    height: $height * 1px !important