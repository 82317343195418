.page-creatives

  &-sidebar

    &-card

      &-control

        &-select
          font-size: 14px

  &-userlist

    .card
      width: 100%

      .media
        justify-content: space-between

    .collaborate-with
      border-radius: .5rem
      padding: 1rem

      p
        color: $purple-light

    .send-message
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 1rem
