$control-radius: $radius !default
$control-radius-small: $radius-small !default

$control-border-width: 1px !default

$control-height: 2.25em !default
$control-line-height: 1.5 !default

$control-padding-vertical: calc(0.375em - #{$control-border-width}) !default
$control-padding-horizontal: calc(0.625em - #{$control-border-width}) !default

=control
  -moz-appearance: none
  -webkit-appearance: none
  align-items: center
  border: $control-border-width solid transparent
  border-radius: $control-radius
  box-shadow: none
  display: inline-flex
  font-size: $size-normal
  height: $control-height
  justify-content: flex-start
  line-height: $control-line-height
  padding-bottom: $control-padding-vertical
  padding-left: $control-padding-horizontal
  padding-right: $control-padding-horizontal
  padding-top: $control-padding-vertical
  position: relative
  vertical-align: top
  // States
  &:focus,
  &.is-focused,
  &:active,
  &.is-active
    outline: none
  &[disabled],
  fieldset[disabled] &
    cursor: not-allowed

%control
  +control

// The controls sizes use mixins so they can be used at different breakpoints
=control-small
  border-radius: $control-radius-small
=control-medium
  font-size: $size-medium
=control-large
  font-size: $size-large
