// Colors

$black:        #223141 !default
$black-bis:    hsl(0, 0%, 7%) !default      // #121212
$black-ter:    hsl(0, 0%, 14%) !default     // #242424

$grey-darker:  hsl(211, 31%, 19%) !default  // #21303f
$grey-dark:    #3B465F !default
$grey:         #F5F6FA !default
$grey-light:   #DEE1EE !default
$grey-lighter: hsl(0, 0%, 86%) !default     // #dbdbdb

$white-ter:    hsl(0, 0%, 96%) !default     // ##f5f5f5
$white-bis:    hsl(0, 0%, 98%) !default     // #FAFAFA
$white:        hsl(0, 0%, 100%) !default
$white-light:  hsl(228, 20%, 95%) !default  // #F0F1F5

$purple:            hsl(240, 50%, 51%) !default
$purple-light:      hsl(229, 23%, 67%) !default  // #979EBE
$purple-lighter:    #C3C7D7 !default

$orange:       hsl(39, 98%, 49%) !default   // #F8A102
$yellow:       hsl(48,  100%, 67%) !default
$green:        hsl(141, 71%,  48%) !default
$cyan:         hsl(204, 86%,  53%) !default
$blue:         hsl(217, 71%,  53%) !default
$purple:       hsl(271, 100%, 71%) !default
$red:          hsl(348, 100%, 61%) !default

// Typography

$family-sans-serif: 'Rubik', sans-serif, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default
$family-monospace: monospace !default
$render-mode: optimizeLegibility !default

$size-1: 3rem !default
$size-2: 2.5rem !default
$size-3: 2rem !default
$size-4: 1.5rem !default
$size-5: 1.25rem !default
$size-6: 1rem !default
$size-7: 0.75rem !default
$size-8: 0.625rem !default

$weight-light: 300 !default
$weight-normal: 400 !default
$weight-medium: 500 !default
$weight-semibold: 600 !default
$weight-bold: 700 !default

// Spacing

$block-spacing: 1.5rem !default

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default
$widescreen-enabled: true !default
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default
$fullhd-enabled: true !default

// Miscellaneous

$easing: ease-out !default
$radius-small: 2px !default
$radius: 4px !default
$radius-large: 6px !default
$radius-rounded: 290486px !default
$speed: 86ms !default

// Flags

$variable-columns: true !default

// Toastify notifications
$notification-bg-green: #effaf3
$notification-color-green: #257942

$notification-bg-yellow: #fffbeb
$notification-color-yellow: #947600

$notification-bg-red: #feecf0
$notification-color-red: #cc0f35

$notification-bg-blue: #eef6fc
$notification-color-blue: #1d72aa
