.page-sign-up
  position: relative
  width: 100%
  align-items: center
  justify-content: center
  flex-direction: column
  background-color: rgba(203, 218, 255, 0.1)

  &-container
    background-image: url('../../assets/images/register/bg.svg')
    background-repeat: no-repeat
    background-attachment: fixed
    background-position: bottom

    +mobile
      background: none
      padding-top: 3rem

    .columns
      padding-top: 3rem

      +tablet
        height: 100vh

      .column
        +mobile
          padding: 0

  .navbar-mobile
    &.is-flex
      display: none !important

  &-welcome
    position: relative
    height: 100%
    left: 0
    top: 0
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center

    +mobile
      background-image: url('../../assets/svg/sign-up-bg.svg')
      background-repeat: no-repeat
      background-attachment: fixed
      background-position: left

    &-title,
    &-description
      text-align: center
      font-family: "Rubik"

    &-title
      font-style: normal
      font-size: 35px
      line-height: 120%

    &-image-rectangle
      background: transparent
      z-index: 10
      width: 80%

    &-description
      font-size: 26px
      line-height: 115%

  &-form
    display: flex
    align-items: center
    justify-content: center
    position: relative
    right: 0
    height: 100%
    top: 0

    +mobile
      background: $white

    .card
      max-width: none

      +mobile
        box-shadow: none

    .form-box
      top: 0
      bottom: 0
      right: 0
      margin: auto
      width: 380px
      height: fit-content
      left: 0
      padding: 20px
      z-index: 10

    &-terms
      display: flex
      align-items: center
      height: 38px
      background: $white

      &-check
        width: 24px
        height: 24px
        border-radius: 8px

      &-links
        padding-left: 8px
        padding-bottom: 8px
        padding-top: 8px
        font-family: "Rubik"
        font-style: normal
        font-weight: normal
        font-size: 0.75rem
        line-height: 16px
        color: $grey-dark

  .field-login
    margin: 10px 0

    > p
      font-size: 0.75rem
      margin-top: 10px

  .divider-box
    max-width: 960px
    text-align: center
    width: 100%

  .divider-lol
    text-align: center
    display: block
    overflow: hidden
    margin: 0px 0px 20px

    .divider-text
      font-weight: 100
      height: 20px
      position: relative
      display: inline-block
      font-size: 12px

      &::before,
      &::after
        content: ""
        position: absolute
        top: 50%
        height: 1px
        background: #e4e4e4
        width: 99999px

      &::before
        left: 100%
        margin-left: 60px

      &::after
        right: 100%
        margin-right: 60px

  &-bg-image
    position: absolute
    width: 100%
    height: 460px
    left: 0
    bottom: 0
    background-image: url('../../assets/images/register/bg.svg')
    background-repeat: no-repeat
    background-size: cover
    background-position: center

  &-success
    height: 480px
    margin-top: 4rem
    background-color: $white
    border-radius: 8px
    display: flex
    flex-direction: column
    align-items: center
    padding: 60px
    max-width: 430px
    margin-left: auto
    margin-right: auto

    +mobile
      padding: 30px
      margin-left: 0
      margin-right: 0
      max-width: 100%

    img
      margin-top: 2rem

    h4
      margin-top: 1rem

    p
      color: $purple-light
      text-align: center
      margin-top: 1.5rem
