.tooltip
    position: relative
    display: inline-block

    .tooltiptext
        visibility: hidden
        width: 135px
        background: rgba(20, 19, 109, 0.85)
        border-radius: 5px
        padding: .5rem
        color: $white
        position: absolute
        left: 50%
        transform: translateX(-50%)
        z-index: 1
        bottom: 120%
        line-height: initial

        & > span
            margin-bottom: 6px

            &:last-of-type
                margin-bottom: 0

        &::after
            content: ''
            position: absolute
            top: 100%
            left: 50%
            margin-left: -5px
            border-width: 5px
            border-style: solid
            border-color: rgba(20, 19, 109, 0.85) transparent transparent transparent

    &:hover
        .tooltiptext
            visibility: visible
