.page-project
  &-content,
  &-collaborators
    padding: 1.5rem

  &-sidebar
    .card
      border-radius: 4px

      &:not(:first-child)
        margin-top: 1rem

    &-collaborators

      &-list

        &-item

          .button
            height: auto

  &-details
    white-space: pre-wrap

    p,
    ul
      padding-bottom: 1rem

  &-collaborators

    &-columns
      padding-bottom: 0

    &-column
      border: 3px solid #F5F6FA
      border-radius: 4px

  .comments,
  .comment-form
    padding: 0

  .collab-search-modal

    &-card
      min-height: 500px
    
  .collaborators-looking,
  .collaborators-found

    .card
      margin-bottom: .5rem
      
    .dropdown-trigger
      & > img
        vertical-align: middle

.project
  &-control > .is-light
    align-items: center

    > span:first-child
      width: 20px
      height: 20px

  &-header

    .content
      white-space: pre-wrap

    &-level
      align-items: flex-start
      display: flex
      align-items: center

      &--no-genres
        align-items: center

      &-left
        flex-shrink: unset

        &-item
          justify-content: flex-start

    &-tag
      &.tag:not(body)
        height: 20px
        line-height: 1.4
        border-radius: 2px
        background: #DBE5FF
        font-size: 14px

    &-top
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 10px

      &-edit
        top: 0
        right: 0
        span
          font-size: 14px
          color: $black

    &-bottom
      padding-bottom: .5rem

      .level-left .level-item
        margin: 0
        position: relative

        &:not(:first-child)
          margin-left: 2em

          &::before
            content: '·'
            position: absolute
            left: -1.2em

.page-project-list
  .spinner
    display: block
    margin: auto

    &-comments

      &-media
        border-top: none
        margin-top: 0
        padding-top: 0

  &-sidebar

    &-card
      &-content
        background-color: #FBFCFE
        border-radius: 0 0 .5rem .5rem

    &-fans
      background-position: center
      background-size: cover
      border-radius: 100%

.page-project,
.page-project-list
  .video-wrapper
    position: relative
    padding-bottom: 56.25%
    height: 0

    iframe
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%

.project-update
  .panel-header
    height: 205px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    &-update
      &-title
        font-size: 24px
        color: #223141
        margin: 8px
      &-description
        font-size: 16px
        color: #979EBE
        margin: 8px
      &-button
        width: 128px
        color: $purple
        background-color: $grey
        margin: 24px
        padding: 8px 16px
        border: none
        border-radius: 4px
        font-size: 14px
        cursor: pointer

        &:hover
          background-color: $purple
          color: $grey
          

  .title
    font-size: 20px

  .sub-title
    color: #979EBE

  .description
    display: block
    overflow-wrap: break-word
    white-space: pre-wrap
