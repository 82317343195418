.badge

  &[data-badge]:after
    content: attr(data-badge)
    position: absolute
    bottom: -6px
    right: -9px
    font-size: 10px
    font-weight: 600
    background: linear-gradient(0deg, #FF5555, #FF5555)
    border-radius: 6px
    color: white
    width: 18px
    height: 16px
    line-height: 16px
    text-align: center