::-webkit-scrollbar
  width: .5rem

::-webkit-scrollbar-track
  background: $white-bis

::-webkit-scrollbar-thumb
  background: hsla(222, 23%, 30%, 0.5)
  border-radius: .5rem

::-webkit-scrollbar-thumb:hover
  background: hsla(222, 23%, 30%, 0.69)

.page-messages
  &-column

    & > .card
      padding: 2rem
      padding-top: 0.9rem
      height: 100vh
      overflow: auto

    &-controls

      &-card
        border-top-right-radius: 0
        border-bottom-right-radius: 0

    &-list
      border-right: 0.5px solid $grey-light
      overflow: hidden
      border-radius: .5rem 0 0 .5rem

      &.column
        padding-top: 2rem

      &-item
        cursor: pointer
        &-new
          cursor: pointer
          display: block
          width: 100%
          border: 1px solid $grey-lighter
          border-radius: 4px

          > .dropdown
            width: 100%

          .dropdown-trigger
            padding: 1rem
            display: flex
            width: 100%

          .dropdown-menu
            width: 100%

          .media.dropdown-item
            margin-bottom: 0
            margin-top: 0

        &-sender
          color: $grey-dark

        &-time
          opacity: 0.31

        &-title
          display: block

          &.unread
            position: relative

            &::before
              content: ''
              width: 8px
              height: 8px
              background: $primary
              border-radius: 50%
              display: inline-block
              position: absolute
              top: 50%
              transform: translateY(-50%)
              left: -1rem

        &-content
          opacity: 0.4

        &.selected,
        &:hover
          background-color: $grey

    &-content

      &-card
        border-top-left-radius: 0
        border-bottom-left-radius: 0

      &-header
        padding-bottom: .50rem

        &.level

          .media
            align-items: center

          img
            vertical-align: middle

        &-right-item
          &:not(:last-child)
            margin-right: 1.5rem

      &-text
        opacity: .69

    &-card

      &-content
        height: calc(100% - 244px)
        width: calc( 100% - 0px)
        overflow-y: scroll
        max-width: 1000px
        padding-right: .75rem

        &-item

          .media:not(:last-child)
            margin-bottom: .5rem
            width: 300px

      &-footer

        &.card-footer
          border: none
          bottom: 12
          padding: 2rem
          padding-top: 0.1rem
          width: 100%
          left: 50%
          transform: translateX(-50%)

    &-footer

      &-action

        &-bar
          justify-content: center
          border: 2px solid $grey-dark
          border-radius: 100px
          max-width: 90px
          margin: -15px auto 0

          &.level img
            vertical-align: middle

      &-form

        .control
          padding-top: 0rem
          border-top: 0px solid $grey-light

        &-textarea

          &.textarea

            &:not([rows])
              max-height: 120px
              //background: red()

        &-button
          margin-left: auto
          width: 6.5em

.custom-select .react-select__control 
  background-color: #f5f5f5
  border: 1px solid #dbdbdb
  border-radius: 4px
  width: 100%


.custom-select .react-select__control--is-focused 
  border-color: #3273dc
  box-shadow: 0 0 0 0.125rem rgba(50, 115, 220, 0.25)


.custom-select .react-select__menu 
  background-color: #fff
  border: 1px solid #dbdbdb
  border-radius: 4px

.custom-select .react-select__menu-list 
  max-height: 200px
  overflow-y: auto

.date-separator 
  text-align: center
  margin: 20px 0
  color: #666
  font-size: 14px


.message-item 
  display: flex
  align-items: flex-start
  margin-bottom: 20px
  gap: 8px


.current-user 
  align-items: flex-start
  justify-content: end


.message-avatar 
  width: 32px
  height: 32px
  border-radius: 50%


.message-content 
  max-width: 70%
  padding: 12px 16px
  border-radius: 18px
  background: #f0f0f0
  position: relative
  color: black

.message-content a
  border-bottom: 1px solid



.current-user .message-content 
  background: #4F5FF4
  color: white


.message-time 
  font-size: 12px
  color: #666
  margin-top: 4px

.current-user a
  color: white
  border-bottom: 1px solid white

.current-user a:hover
  color: #cdcccc
  border-bottom: 1px solid #cdcccc

.current-user .message-time 
  color: rgba(255, 255, 255, 0.8)


/* For PrettyContent */
.enable-line-break 
  white-space: pre-wrap
  word-break: break-word


.current-user .enable-line-break 
  color: white
