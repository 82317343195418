// Float

.is-clearfix
  +clearfix

.is-pulled-left
  float: left !important

.is-pulled-right
  float: right !important

// Overflow

.is-clipped
  overflow: hidden !important

// Overlay

.is-overlay
  @extend %overlay

// Typography

=typography-size($target:'')
  @each $size in $sizes
    $i: index($sizes, $size)
    .is-size-#{$i}#{if($target == '', '', '-' + $target)}
      font-size: $size !important

+typography-size()

+mobile
  +typography-size('mobile')

+tablet
  +typography-size('tablet')

+touch
  +typography-size('touch')

+desktop
  +typography-size('desktop')

+widescreen
  +typography-size('widescreen')

+fullhd
  +typography-size('fullhd')

$alignments: ('centered': 'center', 'justified': 'justify', 'left': 'left', 'right': 'right')

@each $alignment, $text-align in $alignments
  .has-text-#{$alignment}
    text-align: #{$text-align} !important

@each $alignment, $text-align in $alignments
  +mobile
    .has-text-#{$alignment}-mobile
      text-align: #{$text-align} !important
  +tablet
    .has-text-#{$alignment}-tablet
      text-align: #{$text-align} !important
  +tablet-only
    .has-text-#{$alignment}-tablet-only
      text-align: #{$text-align} !important
  +touch
    .has-text-#{$alignment}-touch
      text-align: #{$text-align} !important
  +desktop
    .has-text-#{$alignment}-desktop
      text-align: #{$text-align} !important
  +desktop-only
    .has-text-#{$alignment}-desktop-only
      text-align: #{$text-align} !important
  +widescreen
    .has-text-#{$alignment}-widescreen
      text-align: #{$text-align} !important
  +widescreen-only
    .has-text-#{$alignment}-widescreen-only
      text-align: #{$text-align} !important
  +fullhd
    .has-text-#{$alignment}-fullhd
      text-align: #{$text-align} !important

.is-capitalized
  text-transform: capitalize !important

.is-lowercase
  text-transform: lowercase !important

.is-uppercase
  text-transform: uppercase !important

.is-italic
  font-style: italic !important

@each $name, $pair in $colors
  $color: nth($pair, 1)
  .has-text-#{$name}
    color: $color !important
  a.has-text-#{$name}
    &:hover,
    &:focus
      color: darken($color, 10%) !important
  .has-background-#{$name}
    background-color: $color !important

@each $name, $shade in $shades
  .has-text-#{$name}
    color: $shade !important
  .has-background-#{$name}
    background-color: $shade !important

.has-text-weight-light
  font-weight: $weight-light !important
.has-text-weight-normal
  font-weight: $weight-normal !important
.has-text-weight-medium
  font-weight: $weight-medium !important
.has-text-weight-semibold
  font-weight: $weight-semibold !important
.has-text-weight-bold
  font-weight: $weight-bold !important

.is-family-primary
  font-family: $family-primary !important

.is-family-secondary
  font-family: $family-secondary !important

.is-family-sans-serif
  font-family: $family-sans-serif !important

.is-family-monospace
  font-family: $family-monospace !important

.is-family-code
  font-family: $family-code !important

// Visibility

$displays: 'block' 'flex' 'inline' 'inline-block' 'inline-flex' 'table'

@each $display in $displays
  .is-#{$display}
    display: #{$display} !important
  +mobile
    .is-#{$display}-mobile
      display: #{$display} !important
  +tablet
    .is-#{$display}-tablet
      display: #{$display} !important
  +tablet-only
    .is-#{$display}-tablet-only
      display: #{$display} !important
  +touch
    .is-#{$display}-touch
      display: #{$display} !important
  +desktop
    .is-#{$display}-desktop
      display: #{$display} !important
  +desktop-only
    .is-#{$display}-desktop-only
      display: #{$display} !important
  +widescreen
    .is-#{$display}-widescreen
      display: #{$display} !important
  +widescreen-only
    .is-#{$display}-widescreen-only
      display: #{$display} !important
  +fullhd
    .is-#{$display}-fullhd
      display: #{$display} !important

.is-hidden
  display: none !important

.is-sr-only
  border: none !important
  clip: rect(0, 0, 0, 0) !important
  height: 0.01em !important
  overflow: hidden !important
  padding: 0 !important
  position: absolute !important
  white-space: nowrap !important
  width: 0.01em !important

+mobile
  .is-hidden-mobile
    display: none !important

+tablet
  .is-hidden-tablet
    display: none !important

+tablet-only
  .is-hidden-tablet-only
    display: none !important

+touch
  .is-hidden-touch
    display: none !important

+desktop
  .is-hidden-desktop
    display: none !important

+desktop-only
  .is-hidden-desktop-only
    display: none !important

+widescreen
  .is-hidden-widescreen
    display: none !important

+widescreen-only
  .is-hidden-widescreen-only
    display: none !important

+fullhd
  .is-hidden-fullhd
    display: none !important

.is-invisible
  visibility: hidden !important

+mobile
  .is-invisible-mobile
    visibility: hidden !important

+tablet
  .is-invisible-tablet
    visibility: hidden !important

+tablet-only
  .is-invisible-tablet-only
    visibility: hidden !important

+touch
  .is-invisible-touch
    visibility: hidden !important

+desktop
  .is-invisible-desktop
    visibility: hidden !important

+desktop-only
  .is-invisible-desktop-only
    visibility: hidden !important

+widescreen
  .is-invisible-widescreen
    visibility: hidden !important

+widescreen-only
  .is-invisible-widescreen-only
    visibility: hidden !important

+fullhd
  .is-invisible-fullhd
    visibility: hidden !important

// Other

.has-cursor-pointer
  cursor: pointer !important
  pointer-events: initial

.has-flex-direction-column
  flex-direction: column

.has-justify-content-flex-start
  justify-content: flex-start

.has-justify-content-flex-end
  justify-content: flex-end

.has-justify-content-space-between
  justify-content: space-between

.has-height-auto
  height: auto !important

.has-width-auto
  width: auto !important

.has-width-fit-content
  width: fit-content !important

.is-marginless
  margin: 0 !important

.is-paddingless
  padding: 0 !important

.is-radiusless
  border-radius: 0 !important

.is-shadowless
  box-shadow: none !important

.is-unselectable
  @extend %unselectable

.is-relative
  position: relative !important

.is-absolute
  position: absolute

.is-borderless
  border: none !important

.is-bordered-radius
  border-radius: .25rem

.is-cursor-default
  cursor: default !important

.is-dropshadowed
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05)

.is-dropshadowed-3
  box-shadow: 0px 10px 20px rgba(46, 91, 255, 0.07)

.is-light
  color: $purple-light !important

.is-lighter
  color: $purple-lighter

.is-radiusless
  border-radius: 0

.is-small
  font-size: 14px

.is-opaque
  opacity: 1 !important

.is-hover-underline
  &:hover
    text-decoration: underline

.is-overflow-visible
  overflow: visible !important

.is-grid
  display: grid

.enable-line-break
  white-space: pre-wrap

.is-blue
  color: $blue

.flex-1
  flex: 1

.column-gap-1
  column-gap: 1rem

.text-ellipsis
  overflow: hidden
  text-overflow: ellipsis
  width: 150px
  white-space: nowrap
  
.custom-select .css-13cymwt-control
  background: #F0F1F5
  height: 32px
  border: 0

.message-container
  display: grid
  grid-template-columns: 15fr 1fr /* Two equal columns */
  grid-template-rows: 1fr

.reply-button
  display: flex                 /* Use flex to center content vertically */
  align-items: center          /* Center content vertically */
  justify-content: center
  align-self: end
