.page-not-found
  display: flex
  align-items: center
  justify-content: center
  &-content
    margin-top: 20%
    width: 55%

    &-title
      text-align: Center

