.page-landing

    .hero
        margin-bottom: 150px

        +mobile
            margin-bottom: 0

        .section
            +mobile
                display: flex
                flex-direction: column-reverse

    .process
        transform: translateY(-50%)

        +mobile
            border-radius: 0
            transform: translate(0)

    .how-it-works
        margin-top: -100px

        +mobile
            margin-top: 0

        p
            font-size: 1.25rem

            +mobile
                font-size: 1rem
    
    .cta
        .subtitle
            +mobile
                font-size: 1rem
        
        a.button
            font-size: 1.25rem
            padding: 2rem 3rem

            +mobile
                font-size: 18px
                padding: 1rem 1.25rem

    .vision
        .subtitle
            +mobile
                font-size: 1rem
        
    .card
        img
            max-height: 162px

    .collaboration
        height: 632px
        background-position: center
        background-repeat: no-repeat
        background-size: cover

        &.science-art
            background-image: url('../../assets/svg/landing/science-art-bg.svg')
            
            .title
                color: $primary
        
        &.fashion-vr
            background-image: url('../../assets/svg/landing/fashion-vr-bg.svg')

            .title
                color: #FF5FA4
        
        &.film-ai
            background-image: url('../../assets/svg/landing/film-ai-bg.svg')

            .title
                color: #00E2AC

        h2.title
            align-items: center
            font-size: 2.5rem
            justify-content: center

            +mobile
                font-size: 2rem

        +touch
            height: 316px

        +mobile
            height: 200px

    .faq
        .card-header-title
            font-size: 1.5rem

            +mobile
                font-size: 1.25rem

    &-container

        +touch
            margin-top: 52px

    
