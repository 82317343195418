.upload
  &-dropzone
    background: $white
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    border-radius: 4px
    border: 1px solid $grey-lighter
    cursor: pointer
  &-icon
    vertical-align: middle
