.page-home

  .column-left,
  .column-right
    height: 100vh
    max-height: 100vh
    overflow: hidden
    margin: 0

  .column-left
    background: url(https://images.unsplash.com/photo-1521898284481-a5ec348cb555?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2134&q=80)
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    position: relative
    align-items: center
    justify-content: center

  .column-right
    display: flex
    align-items: center
    justify-content: center
    position: relative
    background-color: white

    &-logo
      position: absolute
      top: 0
      margin-top: 30px

      .logo-md
        width: 10em

    form
      width: 320px

    header
      margin: 50px 0 50px 0

      h1
        font-weight: 300
        font-size: 35px
        line-height: 32px

      span
        color: $purple-light

    .field-login
      margin-bottom: 1rem

    .field-register span
      color: $purple-light

    .divider
      background-color: $border
      border: none
      display: block
      height: 1px
      margin: 0.5rem 0
