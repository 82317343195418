%checkbox-radio
  cursor: pointer
  display: inline-block
  line-height: 1.25
  position: relative
  input
    cursor: pointer
  span
    margin-left: .5rem
  &:hover
    color: $input-hover-color
  &[disabled],
  fieldset[disabled] &
    color: $input-disabled-color
    cursor: not-allowed

.checkbox
  @extend %checkbox-radio

  &-container
    padding-left: 1.5rem

    & input
      display: none

      &:checked ~ .checkmark
        background-color: $primary
        border-color: $primary

        &:after
          display: block

    & > .checkmark
      top: 0
      left: 0
      height: 18px
      width: 18px
      border: 2px solid $purple-light
      border-radius: .25rem

      &:after
        content: ''
        position: absolute
        display: none
        left: 4px
        top: -1px
        width: 7px
        height: 14px
        border: solid white
        border-width: 0 3px 3px 0
        -webkit-transform: rotate(45deg)
        -ms-transform: rotate(45deg)
        transform: rotate(45deg)

    &:hover input ~ .checkmark
      border-color: $primary

.radio
  @extend %checkbox-radio
  & + .radio
    margin-left: 0.5em

.is
  &-radio[type="radio"]
    outline: 0
    user-select: none
    display: inline-block
    position: absolute
    opacity: 0
    vertical-align: baseline

    &:checked

      &+label

        &:after
          display: inline-block
          background: #3b465f

    &+label
      line-height: 1.25rem
      position: relative
      display: initial
      cursor: pointer
      vertical-align: middle
      border-radius: 4px

      &:before,
      &:after
        position: absolute
        left: 0
        width: 1.25rem
        height: 1.25rem
        border-radius: 50%
        content: ''

      &:before
        top: 50%
        transform: translateY(-50%)
        border: 0.1rem solid #3b465f

      &:after
        transform: scale(0.5)
        display: none
        content: ''

.switchradio
  overflow: hidden

  &-input
    clip: rect(0, 0, 0, 0)
    bottom: 0

    &:checked

      &+ label
        background: $primary
        border: none
        color: $white

  &-label
    transition: all 0.1s ease-in-out
    background: $white
    color: $purple-light
    max-width: 202px

    +mobile
      max-width: unset

    &.is-small
      font-size: 14px

    &:hover
      color: $primary
      border-color: $primary

    &:not(:last-child)
      margin-right: 1rem
      +mobile
        margin-bottom: 1rem

        &:not(:last-child)
          margin-right: 0