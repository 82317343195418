.post-card
  background-color: $white
  border-radius: 8px

  h3
    color: $black

  &-header,
  &-body,
  &-media,
  &-collaborators,
  &-stats
    padding: 1rem 1.5rem

  &-header

    &-collaborators

      &-item
        margin-bottom: .5em
        height: 21px
        line-height: initial

        .tag:not(body)
          background: none
          color: $purple-lighter
          height: auto
          line-height: normal

          img
            margin-right: .25rem

      &.buttons
        margin-bottom: initial

      .tag
        font-size: 14px
        font-weight: 700

  &-feature
    &-image
      display: flex

      &-item
        height: 100%
        width: 100%

  &-body
    &-images
      display: flex

      &-item
        height: 260px
        width: 100vw
        background-size: cover
        background-position: center
        background-repeat: no-repeat

  &-collaborators
    padding-top: .75rem
    padding-bottom: .5rem

    img
      margin-right: .5rem

    & > p
      margin-bottom: .5rem

  &-label
    padding: .5rem 1.5rem .5rem
    
    &-item

      &.level-item
        &:not(:last-child)
          margin-right: 0

      &:not(:first-child)
        margin-left: 2em

        &::before
          content: '·'
          position: absolute
          left: -1.2em

  &-stats

    &-item
      margin-right: 1.5em
      align-items: center
      margin-top: 5px
      margin-bottom: 5px

      i.icon
        margin-left: 0
        margin-right: .25em

      &:first-child
        margin-left: 0

    hr
      margin: 0 0 .5rem
