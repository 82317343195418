.page-sign-in
  position: relative
  width: 100%
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  flex-grow: 1

  &-form
    display: flex
    flex: 1 1 0
    align-items: center
    justify-content: center
    position: relative
    height: 100%

    .form-box
      margin: auto
      width: 500px
      height: fit-content
      padding: 20px
      z-index: 10

      .content
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column

      .divider-box
        max-width: 960px
        text-align: center
        width: 100%

      .divider-lol
        text-align: center
        display: block
        overflow: hidden
        margin: 0px 0px 20px

        .divider-text
          font-weight: 100
          height: 20px
          position: relative
          display: inline-block
          font-size: 12px

          &::before,
          &::after
            content: ""
            position: absolute
            top: 50%
            height: 1px
            background: #e4e4e4
            width: 99999px

          &::before
            left: 100%
            margin-left: 60px

          &::after
            right: 100%
            margin-right: 60px
