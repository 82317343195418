$small: 14px

.page-add-project

  &-container

    &.columns
      &:last-child
        +desktop
          margin-bottom: 3rem

        +touch
          margin-bottom: 16rem

  &-card
    &-content
      &-heading
        margin-bottom: 1.25rem
        &-icon
          margin-left: .75rem

      &-genre
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr))

        &.field-body
          +touch
            display: unset

          & > .field
            margin-bottom: 1rem

      &-collaborators
        flex-direction: column
        &-card
          border-radius: .25rem
          .field
            &.has-addons .control
              .input:not([disabled]):focus:hover
                z-index: 3
              &:not(:last-child)
                margin-right: 0

      &-switchradio

        &-label
          border-radius: .5rem
          margin-right: 1rem
          height: auto

          &-image,
          &-selected
            height: 20px
            width: 20px

          &-image
            opacity: .5

          &:last-child
            margin-right: 0

      &-control

        &.has-icons-right

          .icon
            pointer-events: initial
            cursor: pointer

      &-input
        margin-bottom: .5rem

        &.is-small
          font-size: $small

      &-label
        font-size: 10px

      &-select

        select
          font-size: $small

      &-table

        td
          vertical-align: middle

      &-tag
        margin-right: .5rem
        margin-bottom: .5rem

        &:last-of-type
          margin-right: 0

      .control-collaboration
        .icon.icon-plus
          padding: 0.5rem

        > p
          line-height: 2.5
          vertical-align: middle
          cursor: pointer

  &-controls
    button[type="submit"]
      +mobile
        margin-bottom: 1.5rem
      +tablet
        margin-bottom: 1.5rem
      +desktop
        margin-bottom: 0

    &-container
      padding: 1.5rem

      +mobile
        flex-direction: column-reverse
      +tablet
        flex-direction: column-reverse
      +desktop
        flex-direction: row

      &-level
        &.level-left, &.level-right
          +mobile
            margin-top: 0 !important
            margin-bottom: 0 !important

        +touch
          display: block

          &:not(:last-child)
            margin-bottom: 1rem

    &-button

      &.button
        width: 10em

        +touch
          width: 100%

        .img
          width: 1rem

      &:first-child
        +touch
          margin-bottom: 1rem

      &:not(:last-child)
        margin-right: 1rem
