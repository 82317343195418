.accordion
  cursor: pointer
  padding: 18px
  display: flex
  align-items: center
  border: none
  outline: none

  &__section
    display: flex
    flex-direction: column

  &__icon
    margin-left: auto
    transition: transform 0.6s ease

  &__content
    background-color: white
    overflow: hidden
    transition: max-height 0.6s ease

  &--rotate
    transform: rotate(180deg)
