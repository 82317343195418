.page-onboarding
  background: $white
  min-height: 100vh

  .columns > .column
    margin: 0 auto

  header

    h1
      color: $grey-dark
      line-height: 120%

      > span
        color: $primary

    > span
      line-height: 140%

  .textarea
    height: 140px

  .error
    margin: -40px 0 20px 0
    color: $red
