.progress-bar
  &-wrap
    background: $primary
    height: 3px
    left: 0
    position: fixed
    top: 0
    width: 100%
    z-index: 1031

  &-line
    box-shadow: 0 0 10px $primary, 0 0 5px $primary
    display: block
    height: 100%
    opacity: 1
    position: absolute
    right: 0
    transform: rotate(3deg) translate(0px, -4px)
    width: 100px

  &-loading-spinner-wrap
    display: block
    position: fixed
    right: 15px
    top: 15px
    z-index: 1031

  &-loading-spinner
    animation: 400ms linear infinite progress-loading-spinner
    border-bottom: 2px solid transparent
    border-left: 2px solid $primary
    border-radius: 50%
    border-right: 2px solid transparent
    border-top: 2px solid $primary
    box-sizing: border-box
    height: 18px
    width: 18px

@keyframes progress-loading-spinner
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
