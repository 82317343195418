.Toastify
  &__toast
    border-radius: 4px

    &-container
      min-width: 320px
      width: auto

    &--error
      background-color: $notification-bg-red
      color: $notification-color-red

      .Toastify__close-button
        color: $notification-color-red

    &--info
      background-color: $notification-bg-blue
      color: $notification-color-blue

      .Toastify__close-button
        color: $notification-color-blue

    &--success
      background-color: $notification-bg-green
      color: $notification-color-green

      .Toastify__close-button
        color: $notification-color-green

    &--warning
      background-color: $notification-bg-yellow
      color: $notification-color-yellow

      .Toastify__close-button
        color: $notification-color-yellow
